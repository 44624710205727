<template lang="pug">
  Main#jobs
    section
      .container
        .row.center-lg.center-xs.middle-xs
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            h1 {{ $t("jobs.title") }}
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            #astronauts.box-row
              img(class="astronauta-vr floating", src="../../assets/images/astronauta-vr.png")
              img(class="meteorito-2 float-rotate", src="../../assets/images/meteorito02.svg")
              img(class="meteorito-3 float-rotate", src="../../assets/images/meteorito03.svg")
              img(class="meteorito-4 float-rotate", src="../../assets/images/meteorito04.svg")
    section#oportunities
      .container
        .row.center-lg.center-xs.middle-xs
          .col-lg-10.col-md-10.col-sm-10.col-xs-10       
            h2  {{ $t("jobs.oportunities.title") }}
            p {{ $t("jobs.oportunities.text_1") }}
            p {{ $t("jobs.oportunities.text_2") }}
            p {{ $t("jobs.oportunities.text_3") }}
            p {{ $t("jobs.oportunities.text_4") }}
    section#openings
      .container
        .row.center-lg.center-xs.middle-xs
          .col-lg-12.col-md-10.col-sm-10.col-xs-10
            .box
              .col-lg-4.col-md-6.col-sm-6.col-xs-12(v-for="opening in openings")
                .card
                  h4 {{opening.title}}
                  ul
                    li(v-for="requirement in opening.requirements")  {{requirement.skill}}
                  a.button(:href="opening.link" target="_blank") {{ $t("jobs.apply")}}
    section
      ContactSection
</template>

<script>
import Main from "../Template/Main";
import Slick from "vue-slick";
import ContactSection from "../../components/ContactSection/ContactSection";

export default {
  name: "Jobs",
  components: {
    Main,
    ContactSection,
    Slick
  },
  data() {
    return {
      isMobile: false,
      openings: [
        {
          title: "Desenvolvedor Front-end Pleno",
          requirements: [
            {
              skill: "Html5, css 3, javascript"
            },
            {
              skill: "Git, sass, less"
            },
            {
              skill: "Fluent or advanced knowledge of english is required"
            },
            {
              skill: "Fluent or advanced knowledge of english is required"
            },
            {
              skill: "Fluent or advanced knowledge of english is required"
            },
            {
              skill: "Fluent or advanced knowledge of english is required"
            },
            {
              skill: "Fluent or advanced knowledge of english is required"
            }
          ],
          link:
            "https://br.linkedin.com/jobs/view/desenvolvedor-front-end-pleno-at-2mundos-1311053130?position=1&pageNum=0&trk=guest_job_search_job-result-card_result-card_full-click"
        },
        {
          title: "Desenvolvedor Full Stack Pleno",
          requirements: [
            {
              skill: "Html5, css3, javascript."
            },
            {
              skill:
                "At least one of the following frameworks: angular, ember, vue, react, backbone, flutter."
            },
            {
              skill: "Git, sass, less"
            }
          ],
          link:
            "https://br.linkedin.com/jobs/view/desenvolvedor-php-pleno-at-2mundos-1311052484?position=3&pageNum=0&trk=guest_job_search_job-result-card_result-card_full-click"
        },
        {
          title: "Desenvolvedor PHP Pleno",
          requirements: [
            {
              skill: "Good communications skills in spoken english"
            },
            {
              skill: "Ability to follow code standards is a must"
            },
            {
              skill: "Php5 (raw php with no frameworks)"
            }
          ],
          link:
            "https://br.linkedin.com/jobs/view/desenvolvedor-front-end-pleno-at-2mundos-1311053130?position=1&pageNum=0&trk=guest_job_search_job-result-card_result-card_full-click"
        }
      ]
    };
  },
  methods: {
    mobileBreakpointCheck() {
      let vm = this;
      let breakPoint = 1023;
      if (window.innerWidth > breakPoint) {
        vm.isMobile = false;
      } else {
        vm.isMobile = true;
      }
    }
  },
  created() {
    let vm = this;
    $(window).resize(function() {
      vm.mobileBreakpointCheck();
    });
  },
  mounted() {
    let vm = this;
    vm.mobileBreakpointCheck();
    // Fade in Animations
    /* $(".fadeIn").each(function() {
      const scene = vm.$scrollmagic.scene({
        triggerElement: this,
        offset: -150,
        reverse: true
      });

      vm.$scrollmagic.addScene(
        scene.setTween(
          TweenMax.staggerFrom(
            $(this).find(".fadeIn-item"),
            1,
            {
              y: 40,
              autoAlpha: 0,
              delay: 0,
              ease: Power4.easeOut
            },
            0.2
          )
        )
      );
    }); */
    vm.$bus.$emit("load", false);
  }
};
</script>

<style lang="scss">
main {
  background-color: #000;
  &.management-team {
    background-color: #07061b;
  }
  #openings {
    background: #fff;
    color: #07061b;
    .box {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
    }
    .card {
      position: relative;
      box-sizing: border-box;
      width: 90%;
      margin: 0 5% 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      min-height: 500px;
      padding: 2rem;
      border-radius: 0.5rem;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
      h4 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        font-weight: bold;
      }
      ul {
        padding-left: 30px;
        li {
          list-style: disc;
        }
      }
      .button {
        margin-bottom: 0;
      }
    }
  }
}
</style>